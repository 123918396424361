import axios from "axios"

axios.defaults.baseURL = 'https://display-cabinet-drf-dc5ba0599241.herokuapp.com/' 
// axios.defaults.baseURL = 'http://127.0.0.1:8000/' 

// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
// // axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'true'
// // axios.defaults.headers['Access-Control-Allow-Headers'] = '*'
// axios.defaults.withCredentials = true

// define interceptor Request and Response
export const axiosReq = axios.create();
export const axiosRes = axios.create();
