import React, { useEffect, useState } from "react";

//import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import Drawer from "./Drawer";
import Image from "react-bootstrap/Image";
import Asset from "../../components/Asset";

import appStyles from "../../App.module.css";
//import styles from "../../styles/DrawersPage.module.css";
import { useLocation } from "react-router";
import { axiosReq } from "../../api/axiosDefaults";

import { useCurrentUser } from "../../contexts/CurrentUserContext";
import NoResults from "../../assets/no-results.png";
import logo from '../../assets/logo.jpg'

function DrawersPage({ message, filter = "" }) {
  const [drawers, setDrawers] = useState({ results: [] });
  const [hasLoaded, setHasLoaded] = useState(true);
  const { pathname } = useLocation();

  const currentUser =  useCurrentUser();

  console.log('`DP currentUser = ', currentUser)
  // console.log('filter = ', filter)
  // console.log('hasLoaded = ', hasLoaded)

  useEffect(() => {
    const fetchDrawers = async () => {
      try {

        const { data } = await axiosReq.get(`/drawers/?${filter}`);
        setDrawers(data);

        // Stop spinner
        setHasLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };

    // Only load the page and spin if user logged in
    if (currentUser) {
      console.log('Start spinner')
      // Start spinner
      setHasLoaded(false);
      fetchDrawers();
    } 

  }, [filter, pathname]);

  return (
    <Row className="h-100">
      <Col className="py-2 p-0 p-lg-2" lg={8}>
        {/* <p>Popular profiles mobile</p> */}

        {/* only display if user logged in */}

        {hasLoaded  ? (
          <>
            {drawers.length ? (
              drawers.map((drawer) => (
                // {drawer?.owner === currentUser.username ? (
                  <Drawer key={drawer.id} {...drawer} setDrawers={setDrawers} />
                // )}
              ))
            ) : (
              <Container className={appStyles.Content}>
                <Asset message={message} />
                <Image
                    className={`${appStyles.FillerImage}`}
                    src={logo}
                  />
              </Container>
            )}
          </>
        ) : currentUser ? (
          <Container className={appStyles.Content}>
            <Asset spinner />
          </Container>
        ) : null}
      </Col>
      <Col md={4} className="d-none d-lg-block p-0 p-lg-2">
        {/* <p>Popular profiles for desktop</p> */}
      </Col>
    </Row>
  );
}

export default DrawersPage;
